import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				SSS | Justitia Hukuk Danışmanları SSS
			</title>
			<meta name={"description"} content={"Justitia Hukuk Danışmanları olarak, hukuki meselelerin karmaşık ve kafa karıştırıcı olabileceğinin farkındayız."} />
			<meta property={"og:title"} content={"SSS | Justitia Hukuk Danışmanları SSS"} />
			<meta property={"og:description"} content={"Justitia Hukuk Danışmanları olarak, hukuki meselelerin karmaşık ve kafa karıştırıcı olabileceğinin farkındayız."} />
			<meta property={"og:image"} content={"https://erimides.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://erimides.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://erimides.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://erimides.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://erimides.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://erimides.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://erimides.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" quarkly-title="FAQ-8">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				Sıkça Sorulan Sorular
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#555a5f" sm-margin="0px 0px 50px 0px">
				Justitia Hukuk Danışmanları olarak, hukuki meselelerin karmaşık ve kafa karıştırıcı olabileceğinin farkındayız. İşte müşterilerimizden aldığımız en yaygın sorulardan bazılarının yanıtları.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Hangi hukuki alanlarda uzmanlaşıyorsunuz?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Şirketler hukuku, aile hukuku, ceza savunması, gayrimenkul, iş hukuku ve fikri mülkiyet dahil olmak üzere çeşitli hukuki alanlarda hizmet sunuyoruz.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Nasıl danışmanlık alabilirim?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Bizimle telefon veya e-posta yoluyla iletişime geçerek bir konsültasyon planlayabilirsiniz. Sizin için uygun bir zamanda bir toplantı ayarlayacağız.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						İlk görüşmeye ne getirmeliyim?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Davanız veya hukuki meselenizle ilgili her türlü belgeyi yanınızda getirmeniz faydalı olacaktır. Ayrıntılı bilgiler daha net bir resim sunabilir ve size daha doğru tavsiyelerde bulunmamıza yardımcı olabilir.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Uzaktan danışmanlık hizmeti sunuyor musunuz?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Evet, esneklik ihtiyacını anlıyor ve telefon veya video konferans yoluyla danışmanlık hizmeti sunuyoruz.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Ücretleriniz nasıl yapılandırılıyor?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Ücretlerimiz davanın niteliğine ve karmaşıklığına bağlı olarak değişmektedir. Şeffaflığı hedefliyoruz ve tüm ücret yapılarını önceden tartışacağız.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Beni mahkemede temsil edebilir misiniz?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Kesinlikle. Ekibimizde sizi mahkeme süreçlerinde temsil edebilecek deneyimli avukatlar bulunmaktadır.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});